import { log } from '@/services/Log'
import { useState, useEffect } from 'react'

const MOBILE_SCREEN_WIDTH = 768

export const useMobileDetect = () => {
  const [windowWidth, setWindowWidth] = useState(MOBILE_SCREEN_WIDTH)
  const [isMobile, setIsMobile] = useState(true)
  useEffect(() => {
    setWindowWidth(window.innerWidth)
    const windowWidthEventListener = () => {
      log('windowWidthEventListener', window.innerWidth)
      setWindowWidth(window?.innerWidth)
    }
    window?.addEventListener('resize', windowWidthEventListener)
    return () => {
      window?.removeEventListener('resize', windowWidthEventListener)
    }
  }, [windowWidth])

  useEffect(() => {
    if (windowWidth <= MOBILE_SCREEN_WIDTH && !isMobile) {
      setIsMobile(true)
    } else if (isMobile && windowWidth > MOBILE_SCREEN_WIDTH) {
      setIsMobile(false)
    }
  }, [windowWidth, isMobile])

  return isMobile
}

export default useMobileDetect
