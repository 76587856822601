import React from 'react'
import { useMobileDetect } from '@/hooks/useMobileDetect'
import dynamic from 'next/dynamic'
import styles from './ExclusionsApplyModal.module.scss'

const Text = dynamic(import('@csc/dls/Text'))
const CloseIconBold = dynamic(import('@csc/dls/Icons/CloseIconBold'))

const Modal = dynamic(import('@csc/dls/Modal'))

const ExclusionsApplyModal: React.FC<{
  open: boolean,
  onClose: () => void
}> = ({ open, onClose }) => {
  const isMobile = useMobileDetect()

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeable
      closeButton
      closeIcon={CloseIconBold}
      closeIconSize="sm"
      rounded
      type={isMobile ? 'slide' : undefined}
    >
      {open && (
        <div className={styles.mainContent}>
          <Text bold size="xl">Save 20% today</Text>

          <Text className="mt-4">
            Valid online for first-time registrants only. Applies to regular priced items only.
          </Text>

          <Text className="mt-4">
            <span className="font-bold">To redeem offer: </span>
            {' '}
            Online enter code at checkout.
          </Text>

          <Text className="mt-4">
            <span className="font-bold">Offer excludes the following: </span>
            Vintage items, prior purchases, and gift cards.
          </Text>

          <Text className="mt-4">
            <span className="font-bold">Excluded brands: </span>
            {`AERIN, AH Alexa Hampton x Generation Lighting, 
            Amber Lewis for Visual Comfort Signature, All Across Africa, 
            Antebellum Farmhouse, Arteriors, Artist Collective, Assouline, Atelier Choux, 
            Barbara Barry for Visual Comfort Signature, Baobab, BergHOFF International, 
            Barclay Butera, Beth Webb for Arteriors, Bole Road Textiles, 
            Brownstone Furniture, Bunny Williams for Dash & Albert, 
            Bunny Williams Home, C&S Acosta, C.O. Bigelow, Campania International, 
            Caracole, Caskata, CFC, Chelsea House, Chapman & Meyers for Visual Comfort, 
            Champalimaud for Visual Comfort Signature, Christiane Lemieux for Visual Comfort Studio, 
            Christopher Spitzmiller, Celerie Kemble, Claus Porto, Coral & Tusk, Currey & Company, 
            Cyan, Dana Gibson, DASH & ALBERT, DEEPA GURNANI, Drew Doggett Photography, ED Ellen Degeneres, 
            ED Ellen Degeneres x Generation Lighting, Essential Lighitng for Arteriors, Ethnicraft, Feiss, 
            FlashPoint Candle, Florence Broadhurst for Selamat, FS Objects, 
            Generation Brands, GEORGE KOVACS, Gien, Global Views, Hazel Village, 
            Hickory White, Iittala, J. Randall Powers for Visual Comfort Signature, 
            Joanna Buchanan, Juliska, Juniper Books, kate spade new york, 
            Kelly Wearstler for Generation Lighting, LAFCO New York, Lenox Corporation, 
            Lexington, Lexington Home Brands, Lemieux et Cie, Lillian August, 
            Lulu DK for Matouk, M Design Village, 
            Marco Fine Arts, Marie Flanigan for Visual Comfort Signature, Mark D. Sikes, Matouk, Mepra, 
            Metropolitan Lighting Fixture, MIGNONNE GAVIGAN, Minka Lavery, 
            Minke Aire, Miyabi, Monte Carlo Fans, Mosser Glass, Mr & Mrs Howard,
            Ngala Trading Co., Noir, Notre Monde, OEUF, Pine Cone Hill, Paragon, 
            Peacock Alley, Pehr Designs, Penguin Random House, PENGUIN RANDOM HOUSE LLC, 
            Paloma Contreras for Visual Comfort Studio, 
            Paloma Contreras for Visual Comfort Signature, Penguin Random House, 
            Inc., Perennials Social, Phaidon, Pom Pom at Home, Ralph Lauren, Ralph 
            Lauren Home, Ray Booth for Arteriors, Ricci, Royal Copenhagen, SFERRA, 
            Shana Gulati, Simon Paul Scott, Spode, Suzanne Kasler for Visual Comfort Signature, 
            Stanley Furniture, Stark Studio Rugs, Staub, Stokke, STOW, TECH LIGHTING , 
            The Beach People, The Matouk Schumacher Collection, Theodore Alexander, 
            Thom Filicia, Thomas O'Brien x Monte Carlo Fans, Thomas O'Brien for Visual Comfort, Tommy Bahama, 
            Twos Company, Unbranded, Uttermost, Vanguard, Visual Comfort & Co., Voyage et Cie, 
            Wagberry, WALLSHOPPE, Wildwood, York Wallcoverings`}
          </Text>

          <Text className="mt-4">
            <span className="font-bold">Additional offer details: </span>
            {
            // eslint-disable-next-line max-len
            'Cannot be combined with any other offers or discounts. No adjustments to prior purchases. One-time use only.'
            }
          </Text>
        </div>
      )}
    </Modal>
  )
}

export default ExclusionsApplyModal
